.noticiasView__container {
  min-height: 90vh;
  background-color: hsla(206, 69%, 94%, 1);
}

.noticiasView__container__header {
  background-color: hsla(206, 69%, 94%, 1);
  background-image: radial-gradient(
      at 35% 91%,
      hsla(269, 36%, 76%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 84% 31%, hsla(321, 45%, 56%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(22, 100%, 77%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(258, 46%, 25%, 0.54) 0px, transparent 50%);
  padding: 120px 0 20px 0;
  margin-bottom: 20px;
}

.noticiasView__nuevaNoticia {
  display: grid;
  justify-items: center;
}

.noticiasView__nuevaNoticia__btn {
  width: 140px;
  background-color: #362360;
  padding: 5px 12px 5px 12px;
  font-weight: 600;
  font-size: 14px;
  color: hsla(206, 69%, 94%, 1);
  cursor: pointer;
  margin: 0;
  margin-top: 15px;
  border-radius: 15px;
}
.noticiasView__noticias {
  display: grid;
  justify-items: center;
  grid-gap: 20px;
}

.noticiasView__h2 {
  font-size: 32px;
  font-weight: 900;
  color: #362360;
}

.noticiasView__buscador {
  display: grid;
  justify-items: center;
}

.noticiasView__buscador input {
  height: 40px;
  width: 400px;
  border: none;
  border-radius: 15px;
  outline: none;
  padding-left: 15px;
}

@media (max-width: 900px) {
  .noticiasView__buscador input {
    height: 40px;
    width: 90%;
    border: none;
    border-radius: 15px;
    outline: none;
    padding-left: 15px;
  }
}
