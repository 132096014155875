.tarjetaChica__container {
  width: 350px;
  border-radius: 15px;
}

.tarjetaChica__img {
  border-radius: 15px;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 180px;
}

.tarjetaChica__img img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  display: block;
  margin: 0 auto;
}

.tarjetaChica__text {
  text-align: start;
}

.tarjetaChica__text h3 {
  color: #362360;
  margin: 0;
  margin-top: 12px;
}
.tarjetaChica__text p {
  color: #362360;
  margin: 0;
}

.tarjetaChica__fecha {
  color: #362360a1 !important;
  font-size: 12px;
}
