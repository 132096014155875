.tarjetaGrande__container {
  min-width: 400px;
  width: 50vw;
  display: grid;
  grid-gap: 5px;
  border-bottom: 3px solid #362360;
  margin-bottom: 10px;
}

.tarjetaGrande__fecha {
  background-color: #362360;
  border-radius: 15px;
  padding: 8px 20px 8px 20px;
  width: 70px;
  height: 15px;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: hsla(206, 69%, 94%, 1);
}

.tarjetaGrande__tarjeta {
  min-height: 250px;

  width: 100%;
  background-color: hsla(206, 69%, 94%, 1);
  border-radius: 15px;
  display: grid;
  align-items: center;
  grid-gap: 15px;
  grid-template-columns: 1fr 2fr;
}

.tarjetaGrande__img {
  border-radius: 15px;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 300px;
}

.tarjetaGrande__img img {
  object-fit: cover;
  height: 100%;
  display: block;
  margin: 0 auto;
}

.tarjetaGrande__texto {
  padding: 10px;
  min-height: 300px;
  text-align: left;
}
.tarjetaGrande__texto h3 {
  color: #362360;
  margin: 0;
  font-size: 32px;
  font-weight: 900;
}

.tarjetaGrande__texto h3:hover {
  text-decoration: underline;
  cursor: pointer;
}

.tarjetaGrande__texto h4 {
  color: #362360;
  margin: 0;
  margin-top: 10px;
}

.tarjetaGrande__texto p {
  color: #362360;
  margin: 0;
  text-align: start;
  margin-top: 10px;
}

.tarjetaGrande__categorias {
  justify-self: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.tarjetaGrande__categorias p {
  background-color: #362360;
  padding: 5px 12px 5px 12px;
  border-radius: 15px;
  text-align: center;
  color: hsla(206, 69%, 94%, 1);
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  user-select: none;
}

.btn__edit__container {
  display: grid;
  justify-items: center;
  align-items: center;
}

.btn__editar {
  width: 120px;
  background-color: #362360;
  padding: 5px 12px 5px 12px;
  font-weight: 600;
  font-size: 18px;
  color: hsla(206, 69%, 94%, 1);
  cursor: pointer;
  margin: 0;
  margin-top: 15px;
  border-radius: 15px;
}

.btn__eliminar {
  background-color: #7a1515;
}

@media (max-width: 900px) {
  .tarjetaGrande__container {
    min-width: 250px;
    min-height: 300px;
    width: 95%;
    display: grid;
    grid-gap: 5px;
    border-bottom: 3px solid #362360;
    margin-bottom: 10px;
  }
  .tarjetaGrande__tarjeta {
    padding: 0;
    width: 100%;
    background-color: hsla(206, 69%, 94%, 1);
    border-radius: 15px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
  }

  .tarjetaGrande__img {
    border-radius: 15px;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 250px;
  }

  .tarjetaGrande__categorias {
    justify-self: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
  }
}
