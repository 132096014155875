.video__container {
  width: 560px;
  height: 550px;
  background-color: hsla(206, 69%, 94%, 1);
  border-radius: 15px;

  margin-top: 25px;
}

.video__title {
  display: grid;
  grid-template-columns: 80px 1fr;
  justify-items: start;
  height: 60px;
  padding: 15px 15px 0 15px;
}

.video__title__img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: black;
  text-decoration: none;
}

.video__title__text {
}

.title__text__h3 {
  margin: 0;
  text-align: left;
  align-self: end;
  font-size: 16px;
  color: #362360;
}
.title__text__p {
  margin: 0;
  text-align: left;
  font-size: 12px;
  color: #362360;
}
.video__desc {
  height: 70px;
}

.video__desc__p {
  padding-left: 20px;
  padding-right: 15px;
  text-align: left;
  color: #362360;
}

.video_iframe {
  background-color: rgba(0, 0, 0, 0.555);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 405px;
  width: 100%;
}
.video_iframe iframe {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

@media (max-width: 900px) {
  .video__container {
    width: 90%;

    height: auto;
    background-color: white;
    border-radius: 15px;
  }
  .video__title {
    display: grid;
    grid-template-columns: 80px 1fr;
    justify-items: start;
    height: 60px;
    padding: 15px 15px 0 15px;
  }
  .video_iframe {
    background-color: rgba(0, 0, 0, 0.555);
    height: 100%;
    width: 100%;
  }

  .video__desc {
    height: auto;
  }
  .video__desc__p {
    padding-top: 15px;
    font-size: 14px;
    margin-top: 20px;
  }
}
