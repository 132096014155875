.container-skills {
  width: 100%;
  min-height: 200px;
  background-color: hsla(206, 69%, 94%, 1);
  background-image: radial-gradient(
      at 35% 91%,
      hsla(269, 36%, 76%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 84% 31%, hsla(321, 45%, 56%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(22, 100%, 77%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(258, 46%, 25%, 0.54) 0px, transparent 50%);
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 25px 0 25px 0;
}

.skills {
  width: 70%;
  height: 100%;
  display: grid;
  justify-items: center;
  grid-template-rows: 50px 1fr;
}


.skills-title {
  width: 50%;
  display: grid;
  justify-items: center;
}
.skills-title h3 {
  color: #362360;
  font-size: 28px;
  margin: 0;
  font-weight: 800;
}

.separador-skills {
  height: 2px;
  width: 60%;
  background-color: #362360;
}

.skills-desc {
  width: 50%;
  border: 1px solid #362360;
  border-radius: 15px;
  padding: 15px;
  min-width: 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 15px;
  margin-top: 15px;
}

.skills-item {
  background-color: rgb(226, 233, 240);
  border-radius: 10px;
  padding: 15px;
  width: 100px;
  display: grid;
  grid-template-rows: 50px 1fr;
  justify-items: center;
  align-items: center;
  transition-duration: 0.5s;
  user-select: none;
}
.skills-item:hover {
  transform: scale(1.05);
}
.skills-item:hover > .skills-icon {
  color: #c35e9e;
}

.skills-item p {
  margin-top: 10px;
  font-weight: 600;
  color: #362360;
  font-size: 16px;
}

.skills-icon {
  color: #362360;
  font-size: 60px;
  transition-duration: 0.5s;
}

.skills-item:hover > .skills-icon {
  transform: scale(1.1);
}
@media (max-height: 720px) {
  .skills-desc {
    width: 80%;
  }
}
@media (min-width: 1500px) {
  .skills-item {
    background-color: rgb(226, 233, 240);
    border-radius: 10px;
    padding: 15px;
    width: 80px;
    display: grid;
    grid-template-rows: 50px 1fr;
    justify-items: center;
    align-items: center;
    transition-duration: 0.3s;
  }
}

@media (max-width: 900px) {
  .skills {
    width: 90%;
    height: 100%;
    display: grid;
    justify-items: center;
    grid-template-rows: 50px 1fr;
  }
  .skills-desc {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
