.about {
  min-height: 500px;
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  background-color: hsla(206, 69%, 94%, 1);
}

.about-container {
  height: 90%;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.foto {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
}

.foto img {
  height: 450px;
}

.info {
  width: 100%;
  height: 100%;
}

.title {
  color: #362360;
  text-align: start;
  font-size: 25px;
  font-weight: 800;
}

.separador {
  width: 50%;
  height: 2px;
  background-color: #362360;
}

.info p {
  color: #362360;
  text-align: start;
  font-weight: 400;
  width: 80%;
}

.btnes__about{
  display: flex;
  gap: 15px;
}

.cv {
  width: 150px;
  padding: 12px 0 12px 0;
  text-align: center;

  margin-top: 15px;
  border-radius: 15px;
  background-color: hsla(206, 69%, 94%, 1);
  text-decoration: none;
  border: 1px solid #362360;
  font-weight: 600;
  transition-duration: 0.3s;
  cursor: pointer;
}
.cv:hover {
  transform: scale(1.01);
  background-color: #362360;
  
}



.cv a {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  
  text-decoration: none;
}
.aCv {
  color: #362360;
}

.cv:hover>.aCv p {
  color: hsla(206, 69%, 94%, 1);

}

.cv a p {
  margin: 0;
  text-align: center;
  font-size: 15px;
  transition-duration: 0.2s;
  font-weight: 600;
}

@media (max-width: 900px) {
  .about-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .foto img {
    height: 350px;
  }
  .about {
    padding: 30px 0 30px 0;
  }

  .info p {
    width: 100%;
  }

  .cv {
    margin-top: 0;
  }
}
