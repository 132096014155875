@import "../../styles/Variables.module.scss";
.home {
  min-height: 100vh;
  width: 100%;
  background-color: hsla(206, 69%, 94%, 1);
  background-image: radial-gradient(
      at 35% 91%,
      hsla(269, 36%, 76%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 84% 31%, hsla(321, 45%, 56%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(22, 100%, 77%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(258, 46%, 25%, 0.54) 0px, transparent 50%);

  display: grid;
  justify-items: center;
}

.home__container {
  width: 70%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.home__title {
  min-width: 460px;
  width: 30vw;
  display: flex;
  flex-direction: column;
}

.title__h1 {
  text-align: center;
  color: #c35e9e;
  margin: 0;
  font-size: 105px;
  font-weight: 900;
  font-family: "Bebas Neue", cursive;
  user-select: none;
  line-height: 0.7 !important;
  text-shadow: 1px 1px 0 white, -1px 1px 0 white, 1px -1px 0 white,
    -1px -1px 0 white;
}

.title__h2 {
  text-align: center;
  color: #362360;
  margin: 0;
  font-family: "Bebas Neue", cursive;
  font-size: 55px;
  user-select: none;
  padding: 0;
  height: fit-content;
  text-shadow: 1px 1px 0 white, -1px 1px 0 white, 1px -1px 0 white,
    -1px -1px 0 white;
}

.title__p {
  text-align: center;
  margin: 0;
  color: #362360;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 500;

  a {
    font-size: 32px;
    cursor: pointer;
    transition-duration: 0.3s;
    margin-top: 5px;
    color: $black-color;

    &:hover {
      color: $white-color;
    }
  }
}

.title__links {
  width: 100%;
  margin-top: 15px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.title__a {
  width: fit-content;
  padding: 12px;
  text-align: center;
  color: #362360;
  border-radius: 10px;
  text-decoration: none;
  border: 2px solid #362360;
  font-weight: 900;
  transition-duration: 0.3s;
  margin: 0;
  cursor: pointer;
}

.title__a__portfolio {
  background-color: #362360;
  color: rgb(229, 241, 250);
  padding: 12px 12px 12px 12px;
}

.title__a:hover {
  background-color: rgb(229, 241, 250);
}

.title__a__portfolio:hover {
  color: #362360;
  background-color: rgb(229, 241, 250);
}

.scale-up-center {
  animation: scale-up-center 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) infinite
    alternate-reverse both;
}
@keyframes scale-up-center {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.03);
  }
}

.home__youtube {
  max-width: 100%;
  display: grid;
  justify-items: center;
}

.home__youtube iframe {
  min-height: 30vh;
  border-radius: 15px;

  background-color: rgba(0, 0, 0, 0.568);
}

.title__h3 {
  font-size: 28px;
  color: #362360;
  font-family: "Bebas Neue", cursive;
  margin: 0;
}

.ver__mas__video {
  width: 140px;
  padding: 12px 0 12px 0;
  text-align: center;
  color: #3623607a;
  margin-top: 15px;
  border-radius: 10px;
  text-decoration: none;
  border: 2px solid #3623607a;
  font-weight: 600;
  transition-duration: 0.3s;
  cursor: pointer;
}
.ver__mas__video:hover {
  border: 2px solid #362360;
  color: #362360;
}

.home__beneficios {
  width: 100%;

  background-color: hsla(206, 69%, 94%, 1);
  display: grid;
  justify-items: center;
}

.free {
  min-height: 400px;
  width: 100%;
  background-color: hsla(206, 69%, 94%, 1);
  background-image: radial-gradient(
      at 35% 91%,
      hsla(269, 36%, 76%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 84% 31%, hsla(321, 45%, 56%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(22, 100%, 77%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(258, 46%, 25%, 0.54) 0px, transparent 50%);
  display: grid;
  justify-items: center;
}
.free__container {
  width: 70%;
  min-width: 400px;
  display: grid;
  justify-items: center;
}

.free__contenido {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
}
.contacto {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background-color: $black-color;
  color: $white-color;
  border: none;
  border-radius: 10px;
  font-family: $fuente1;
  font-weight: 500;
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
  transition-duration: 0.3s;

  &:hover {
    background-color: $white-color;
    color: $black-color;
    box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0;
  }

  .icon {
    margin-top: 3px;
    font-size: 22px;
  }
}

.free__text {
  align-self: center;
  display: grid;
  align-items: center;
  justify-items: center;
  height: 80px;
  width: 100%;
}

.free__text h4 {
  font-size: 42px;
  color: #362360;
  font-family: "Bebas Neue", cursive;
  margin: 0;
}
.free__text p {
  margin: 0;
  color: #c35e9e;
  font-size: 26px;
}
.free__text p span {
  cursor: pointer;
  margin-top: 2px;
  border-bottom: 2px solid rgba(36, 8, 8, 0);
  transition-duration: 0.3s;
  &:hover {
    border-bottom: 2px solid $primary-color;
  }
}

@media (max-width: 900px) {
  .home__container {
    width: 90%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
  }

  .title__mobile {
    height: 100vh;
    display: grid;
    align-items: center;
  }
  .home__title {
    width: 100%;
    align-self: center;
    justify-self: center;
    display: grid;
    min-width: 280px;
  }
  .title__h1 {
    text-align: center;
    color: #c35e9e;
    margin: 0;
    font-size: 65px;
    font-weight: 900;
    font-family: "Bebas Neue", cursive;
    user-select: none;
  }

  .title__h2 {
    text-align: center;
    color: #362360;
    margin: 0;
    font-family: "Bebas Neue", cursive;
    font-size: 35px;
    user-select: none;
  }

  .title__p {
    text-align: center;
    margin: 0;
    color: #362360;
    font-size: 16px;
  }
  .title__links {
    justify-self: center;
  }
  .title__a {
    justify-self: center;
    text-align: center;
  }
  .home__youtube {
    padding-bottom: 50px;
  }

  .home__youtube iframe {
    min-width: 70vw !important;
    min-height: 70vh;
  }
  .home__portafolio__container {
    min-width: 300px;
    width: 95%;
  }
  .free__container {
    width: 95%;
    min-width: 300px;
    display: grid;
    justify-items: center;
  }
  .free__contenido {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
  }

  .free__text {
    height: 200px;
  }
  .free__text h4 {
    width: 70%;
    font-size: 46px;
    color: #362360;
    font-family: "Bebas Neue", cursive;
    margin: 0;
  }
  .free__text p {
    width: 80%;
    margin: 0;
    color: #c35e9e;
    font-size: 26px;
  }
  .free__text p span {
    cursor: pointer;
    font-size: 28px;
  }
  .free__img {
    display: none;
  }

  .noticias__container {
    width: 95%;
    min-width: 60vw;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr;
    padding: 20px 0 20px 0;
  }
}
