@import "../../../styles/Variables.module.scss";

.item__container {
  width: 100%;
  min-width: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 2px solid #362360;
  padding: 15px;
}

.item__img {
  display: grid;
  justify-items: center;
}

.item__img img {
  height: 400px;
}

.item__descripcion {
  height: 300px;
  width: 100%;
  margin-top: 25px;
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.item__descripcion h3 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 32px;
  color: #362360;
}
.item__descripcion h3:hover {
  text-decoration: underline;
}

.item__descripcion p {
  color: #362360;
  margin: 0;
}
.item__p {
  margin-top: 20px !important;
  font-weight: 600;
}

.item__button {
  height: 30px;
  width: fit-content;
  text-align: center;

  margin-top: 20px;
  border: 2px solid #36236000;
  border-radius: 10px;
  background-color: $primary-color;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 900;
  transition-duration: 0.3s;
  text-decoration: none;
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 0 10px 0 10px;

  .item__button__p {
    margin: 0 !important;
    color: $white-color !important;
  }

  &:hover {
    border: 2px solid #362360;
    background-color: $white-color;
    color: #362360;
  }

  &:hover > .item__button__p {
    color: $black-color !important;
  }
}

.item__tecnologias__container {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  grid-gap: 10px;
}

.item__tecnologia {
  height: 30px;
  margin: 0;
  text-align: center;

  background-color: #362360;
  color: white !important;
  border-radius: 15px;
  user-select: none;
  display: grid;
  align-items: center;
  padding: 0 10px 0 10px;
}
.item__tecnologia p {
  margin: 0;
  color: white;
}

@media (max-width: 900px) {
  .item__container {
    min-height: 300px;

    width: 100%;
    min-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border-bottom: 2px solid #362360;
  }
  .item__img img {
    height: auto;
    width: 85vw;
  }
  .item__descripcion {
    height: auto;
    justify-self: center;
    width: 90%;
    margin-top: 0px;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
  }
  .item__button {
    justify-self: center;
  }
  .item__tecnologias__container {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 10px;
  }
}
