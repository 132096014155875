.container-loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: hsla(206, 69%, 94%, 1);
  background-image: radial-gradient(
      at 35% 91%,
      hsla(269, 36%, 76%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 84% 31%, hsla(321, 45%, 56%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(22, 100%, 77%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(258, 46%, 25%, 0.54) 0px, transparent 50%);
}

.container-loader p{
  font-weight: 900;
}

.img__loader{
  height: 60px;
  border-radius: 50%;
  animation: img__loader .8s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate-reverse both;
}


@keyframes img__loader {
  0% {
    transform: rotate(-6deg);
    color: #c35e9e;
  }

  15% {
    transform: rotate(6deg);
    color: #c35e9e;
  }

  30% {
    transform: rotate(0deg);
  }
}