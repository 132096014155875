.header-container {
  width: 100%;
  height: 100vh;
  background-color: hsla(206, 69%, 94%, 1);
  background-image: radial-gradient(
      at 35% 91%,
      hsla(269, 36%, 76%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 84% 31%, hsla(321, 45%, 56%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(22, 100%, 77%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(258, 46%, 25%, 0.54) 0px, transparent 50%);
  display: grid;
  grid-template-rows: 1fr 60px;
}

.header {
  align-self: center;
  justify-self: center;
  display: grid;
  justify-items: center;
  user-select: none;
  grid-gap: 20px;
  min-width: 560px;

  .img {
    position: absolute;
    right: 2px;
    bottom: 0;
    transform: rotate(-90deg);
  }
}
.header__youtube {
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.479);
}

.header__text {
  text-align: center;
}

.header__text h1 {
  font-size: 65px;
  color: #362360;
  font-weight: 600;
  font-family: "Bebas Neue", cursive;
}

.header__text p {
  font-size: 30px;
  color: #c35e9e;
  font-weight: 400;
}

.ciudad {
  color: #362360 !important;
  font-weight: 600 !important;
}

.justificado {
  margin: 0;
}

.botones {
  justify-self: center;
  align-self: center;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 40px);
}

.boton {
  color: white;
  font-size: 30px;
  transition-duration: 0.3s;
}

.boton:hover {
  transform: scale(1.1) translateY(-10px);
  color: rgb(5, 226, 226);
}

@media (max-height: 720px) {
  .header {
    margin-top: 50px;
  }
}

@media (max-width: 900px) {
  .header {
    max-width: 560px;
    width: 90%;
    min-width: 85%;

    .img {
      height: 180px;
      bottom: 60px;
    }
  }

  .header h1 {
    font-size: 40px;
  }
  .ciudad {
    font-size: 22px !important;
  }

  .header p {
    font-size: 20px;
  }
}
