.redes__container {
  align-self: center;
  padding: 10px 8px 10px 8px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background-color: hsla(206, 69%, 94%, 1);
  color: #362360;
  font-size: 32px;
  position: fixed;
  box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0;
  left: 0;
  top: 30vh;
  z-index: 100;
}
.linkedin {
  animation: linkedin 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate-reverse both;
}

@keyframes linkedin {
  0% {
    transform: rotate(-6deg);
    color: #c35e9e;
  }

  15% {
    transform: rotate(6deg);
    color: #c35e9e;
  }
    30% {
      transform: rotate(0deg);
    }
}

.redes__icon {
  cursor: pointer;
  text-decoration: none;
  color: #362360;
  transition-duration: 0.3s;
}
.redes__icon:hover {
  color: #c35e9e;
}

.tiktok{
  font-size: 24px;
  top: -5px;
  position: relative;
}



@media (max-width: 900px) {
  .redes__container {
    align-self: center;
    border-radius: 40px;
    flex-direction: row-reverse;
    
    padding: 5px 15px 5px 15px;
    gap: 15px;
    background-color: rgba(229, 241, 250, 0.76);
    color: #362360;
    font-size: 32px;
    position: fixed;
    box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0;
    left: 2%;
    top: 93%;
    z-index: 80;
  }
  .redes__container a {
    display: grid;
    align-items: center;
  }

    .tiktok {
      font-size: 24px;
      top: 0px;
      position: relative;
    }
}
