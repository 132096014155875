.App {
  text-align: center;
  display: grid;
  background-color: hsla(206, 69%, 94%, 1);
  overflow-x: hidden;
}

#navbar {
  display: grid;
}

span {
  font-weight: 900;
}

a {
  text-decoration: none;
}
