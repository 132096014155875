.item {
  height: 350px;
  width: 300px;
  box-shadow: -1px 12px 28px -11px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 12px 28px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 12px 28px -11px rgba(0, 0, 0, 0.75);
  border-radius: 15px;
  background-size: 180%;
  background-position: center;
  transition: 0.3s ease-in-out;
}
.item-container {
  height: 100%;
  width: 100%;
  background-color: #0000008f;
  border-radius: 15px;
  display: grid;
  align-items: center;
}

.item a {
  text-decoration: none;
}

.item:hover {
  box-shadow: 0px 19px 44px -11px rgba(0, 0, 0, 0.79);
  -webkit-box-shadow: 0px 19px 44px -11px rgba(0, 0, 0, 0.79);
  -moz-box-shadow: 0px 19px 44px -11px rgba(0, 0, 0, 0.79);
  transform: translateY(-5px);
  background-size: 190%;
}
.item h4 {
  margin: 0;
  color: white;
  font-size: 25px;
  font-weight: 800;
}

.item p {
  color: white;
  font-weight: 600;
  width: 80%;
  justify-self: center;
  margin: 0;
  text-shadow: 2px 2px 2px #000000;
}

.tecnologias {
  display: grid;
  justify-items: center;
}

.item-links {
  height: 50px;
  justify-self: center;
  display: grid;
  grid-template-columns: repeat(2, 70px);
  backdrop-filter: blur(5px) brightness(50%);
  border-radius: 15px;
}

.item-solo {
  height: 50px;
  justify-self: center;
  display: grid;
  grid-template-columns: 1fr;
  backdrop-filter: blur(5px) brightness(50%);
  border-radius: 15px;
  width: 100px;
}

.a {
  text-decoration: none;
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
}

.boton-port {
  color: rgb(255, 255, 255);
  font-size: 40px;
  transition-duration: 0.3s;
}

.a:hover > .boton-port {
  font-size: 45px;
  color: rgb(5, 226, 226);
}

.detalles__item {
  width: 100px;
  padding: 5px 10px 5px 10px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.473);
  color: white;
  cursor: pointer;
  justify-self: center;
  display: grid;
  align-items: center;
  justify-items: center;
}

.popup__item {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: #0000006e;
  top: 0;
  backdrop-filter: blur(2px);
  display: grid;
  align-items: center;
  justify-items: center;
  z-index: 300;
}

.popup {
  min-height: 500px;
  width: 50vw;
  min-width: 600px;
  max-width: 650px;
  background-color: white;
  border-radius: 15px;
  display: grid;
  grid-template-rows: 300px 1fr;
  position: relative;
}

.popup__video {
  min-height: 300px;
  background-color: black;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-position: center;
}

.popup__info {
  padding: 15px;
  text-align: start;
}

.popup__info h3 {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.425);
  padding-bottom: 10px;
  font-size: 28px;
  margin-top: 0;
}
.popup__info span {
  font-weight: 900;
  text-decoration: underline;
}

.popup__cerrar {
  position: absolute;
  padding: 8px 13px 8px 13px;
  border-radius: 15px;
  bottom: -50px;
  justify-self: center;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: white;

  cursor: pointer;
}

.popup__link {
  border-top: 1px solid rgba(0, 0, 0, 0.493);
  padding: 8px 12px 8px 12px;
  display: grid;
  align-items: center;
  justify-items: center;
  height: 40px;
}

.popup__link a {
  color: black;
  text-decoration: none;
  font-size: 16px;
  font-weight: 900;
}

.popup__link a:hover {
  color: rgb(0, 124, 155);
}

.popup__cerrar p {
  font-weight: 900;
  margin: 0;
}
@media (max-height: 700px) {
  .popup {
    align-self: start;
    transform: scale(0.9);
  }
}

@media (max-width: 900px) {
  .item:hover {
    transform: translateY(0px);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
    background-size: cover;
  }

  .item {
    height: 300px;
    width: 85%;
  }
  .popup {
    min-height: 500px;
    min-width: 95%;
    width: 90vw;
    background-color: white;
    border-radius: 15px;
    display: grid;
    grid-template-rows: 300px 1fr;
    position: relative;
    align-self: start;
    margin-top: 20px;
  }
  .popup__info {
    font-size: 14px;
  }
}
