@import "../../styles/Variables.module.scss";

.container {
  padding: 80px 0 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  flex-wrap: wrap;

  img {
    border-radius: 50%;
    height: 300px;
  }

  .text {
    text-align: left;
    color: $black-color;
    h3 {
      margin: 0;
      font-size: 42px;
      font-family: $fuente2;
      font-weight: 400;
      line-height: 0.9;
    }

    p {
      width: 350px;
      line-height: 1.7;
    }

    .btn {
      font-family: $fuente1;
      color: $black-color;
      border: 1px solid rgba(30, 4, 61, 0.514);
      padding: 15px;
      border-radius: 15px;
      background-color: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      transition-duration: 0.3s;
      font-weight: 600;

      img {
        height: 16px;
        transition-duration: 0.3s;
      }

      &:hover > img {
        transform: translateX(5px);
      }
      &:hover {
        box-shadow: rgb(100 100 111 / 20%) 0 7px 29px 0;
      }
    }
  }
}
