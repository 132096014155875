.freelance {
  min-height: 100vh;
  width: 100%;
  background-color: hsla(206, 69%, 94%, 1);
  background-image: radial-gradient(
      at 35% 91%,
      hsla(269, 36%, 76%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 84% 31%, hsla(321, 45%, 56%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(22, 100%, 77%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(258, 46%, 25%, 0.54) 0px, transparent 50%);

  display: grid;
  justify-items: center;
}

.freelance__container {
  height: 100vh;
  width: 70%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.title__h1 {
}

.freelance__img {
  justify-self: center;
  height: 400px;
}

.title__p__free {
  text-align: center;
  margin: 0;
  color: #362360;
}

.freelance__beneficios {
  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
  min-height: 300px;
  background-color: hsla(206, 69%, 94%, 1);
}

.freelance__resultados {
  min-height: 300px;
  width: 100%;
  background-color: hsla(206, 69%, 94%, 1);
  display: grid;
  justify-items: center;
}

.resultados__container {
  max-width: 70%;
  width: 60%;
  min-width: 600px;
  display: grid;
  align-items: center;
}
.resultados__h4 {
  font-size: 36px;
  color: #362360;
  margin: 0;
}
.resultados__p {
  font-size: 20px;
  color: #362360;
  margin: 0;
}

.freelance__esperamos {
  width: 100%;
  min-height: 300px;
  background-color: #c35e9e;
  color: hsla(206, 69%, 94%, 1);
  display: grid;
  align-items: center;
  justify-items: center;
  font-size: 36px;

  font-weight: 500;
}

.freelace__form {
  min-height: 70vh;
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: start;
  background-color: rgb(226, 233, 240);
}
.form__title {
  width: 50%;
  text-align: center;
  color: #362360;
  font-size: 34px;
  border-bottom: 2px solid #362360;
  padding-bottom: 20px;
}

.form {
  min-width: 650px;
  width: 40%;
  border: 1px solid #36236052;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 25px;
}

.form__p {
  margin: 0;
  margin-top: 10px;
  padding-left: 20px;
  justify-self: start !important;
  font-size: 18px;
  font-weight: 600;
  color: #362360;
}

.form__input {
  border: none;
  background-color: hsla(206, 69%, 94%, 1);
  width: 95%;
  padding: 15px;
  border-radius: 7px;
  outline: none;
  font-size: 16px;
  color: #362360;
}
.form__input::placeholder {
  color: #362360a1;
  font-family: "Quicksand", sans-serif;
}

.form__area {
  width: 95%;
  min-height: 200px;
  padding: 15px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  outline: none;
  background-color: hsla(206, 69%, 94%, 1);
  color: #362360;
  font-family: "Quicksand", sans-serif;
  resize: none;
}

.form__area::placeholder {
  color: #362360a1;
}

.form__submit {
  width: 99%;
  padding: 10px;
  text-align: center;
  color: #362360;
  margin-top: 15px;
  border-radius: 10px;
  background-color: hsla(206, 69%, 94%, 1);
  text-decoration: none;
  border: 1px solid #3623607a;
  font-weight: 700;
  font-size: 16px;
  transition-duration: 0.3s;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
}

@media (max-width: 900px) {
  .freelace__form {
    min-height: 70vh;
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: start;
  }
  .freelance__container {
    height: 100vh;
    width: 85%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
  }
  .title__p__free {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    color: #362360;
  }

  .resultados__container {
    max-width: 95%;
    width: 90%;
    min-width: 300px;
    display: grid;
    align-items: center;
  }
  .resultados__h4 {
    font-size: 32px;
    color: #362360;
    margin: 0;
  }
  .resultados__p {
    font-size: 16px;
    color: #362360;
    margin: 0;
  }
  .freelance__esperamos {
    font-size: 28px;
  }
  .freelance__esperamos h4 {
    width: 90%;
  }

  .form__title {
    width: 90%;
    text-align: center;
    color: #362360;
    font-size: 25px;
    border-bottom: 2px solid #362360;
    padding-bottom: 20px;
  }
  .form {
    min-width: 280px;
    width: 90vw;
    border: 1px solid #36236052;
    padding: 6px;
    border-radius: 15px;
    display: grid;
    justify-items: center;
    margin-bottom: 25px;

    .form__input {
      width: 80vw;
    }
    .form__area {
      width: 80vw;
    }
  }

  .freelance__img {
    display: none;
    justify-self: center;
    height: 200px;
  }
}
