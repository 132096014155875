.footer {
  height: 80px;
  width: 100%;
  background-color: #231541;

  display: grid;
  justify-items: center;
  align-items: center;
}

.footer-container {
  height: 100%;
  width: 300px;
  display: grid;
  justify-items: center;
}

.footer-container p {
  margin: 0;
  color: hsla(206, 69%, 94%, 1);
  font-size: 15px;
}

.boton-footer {
  color: hsla(206, 69%, 94%, 1);
  font-size: 25px;
  transition-duration: 0.3s;
}

.boton-footer:hover {
  transform: scale(1.1);
  color: #c35e9e;
}
