.container-port {
  min-height: 600px;

  display: grid;
  justify-items: center;
}

.container-portfolio {
  height: 90%;
  width: 70%;
  display: grid;
  grid-gap: 15px;
  grid-template-rows: 50px 1fr;
  margin-bottom: 25px;
  margin-top: 25px;
}
.port-title {
  display: grid;
  justify-items: center;
}

.port-title h3 {
  color: #362360;
  margin: 0;
  font-size: 35px;
  font-weight: 800;
}

.separador-portafolio {
  width: 30%;
  height: 2px;
  background-color: #362360;
}

.port-proyectos {
  width: 100%;
  justify-self: center;
  align-self: center;
  position: relative;
  display: grid;
  justify-items: center;
  grid-gap: 15px;
  grid-template-columns: 1fr;
}

.btn__ver{
  background-color: inherit;
  border: 2px solid #362360;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
  color: #362360;
  cursor: pointer;
  font-size: 20px;
  font-weight: 900;
  font-family: "Quicksand", sans-serif;
  transition-duration: .3s;
}

.btn__ver:hover{
  background-color: #362360;
  color: white;
}

@media (max-width: 900px) {
  .container-portfolio {
    width: 100%;
  }
  .port-proyectos {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
    .btn__ver{
      margin-top: 20px;
      font-size: 20px;
    }
}
