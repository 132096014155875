$primary-color: #c35e9e;
$secondary-color: #863ec6;
$third-color: #7916ff;
$white-color: hsla(206, 69%, 94%, 1);
$nowhite-color: #4b05ad;
$black-color: #291a47;
$fuente1: "Quicksand", sans-serif;
$fuente2: "Manrope", sans-serif;
$fuente2: "Bebas Neue", cursive;
$fuente4: "Poppins", sans-serif;
$sombra: 0 0.07em 0.125em 0 rgba(0, 0, 0, 0.15);
$b-r: 5px;

$fondoGradiente: radial-gradient(
    at 35% 91%,
    hsla(269, 36%, 76%, 1) 0px,
    transparent 50%
  ),
  radial-gradient(at 84% 31%, hsla(321, 45%, 56%, 1) 0px, transparent 50%),
  radial-gradient(at 0% 100%, hsla(22, 100%, 77%, 1) 0px, transparent 50%),
  radial-gradient(at 80% 100%, hsla(258, 46%, 25%, 0.54) 0px, transparent 50%);
$fondoColor: hsla(206, 69%, 94%, 1);

@export "primary-color";
@export "secondary-color";
@export "third-color";
@export "white-color";
@export "black-color";
@export "fuente1";
@export "fuente2";
@export "fuente3";
@export "fuente4";
@export "fondoGradiente";
@export "fondoColor";
@export "sombra";
@export "b-r";
