.nav {
  position: fixed;
  z-index: 100;
  justify-self: center;
  width: 70%;
  height: 60px;
  background-color: #291a47;
  backdrop-filter: blur(5px);
  margin-top: 20px;
  border-radius: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.img__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;

  &:hover > .img__nav {
    opacity: 1;
  }
  &:hover > p {
    color: rgb(255, 255, 255);
  }
  p {
    color: rgb(221, 221, 221);
    font-weight: 600;
    transition-duration: 0.3s;
  }
}

.img__nav {
  opacity: 0.75;
  height: 50px;
  border-radius: 50%;
  margin-left: 5px;
  transition-duration: 0.3s;
}

.navbar-menu {
  grid-column: 3 / 4;
  display: grid;
}

.nav-ul {
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 0;
  padding: 0;
  padding-right: 60px;
  height: 100%;
}

.nav-link {
  list-style: none;
  align-self: center;
  justify-self: center;
  height: 60%;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  min-width: 70px;

  display: grid;
  align-items: center;
  color: hsla(206, 69%, 94%, 1);
  cursor: pointer;
  user-select: none;
}
.nav-link:hover {
  color: #c35e9e;
}

.nav-link-p {
  margin: 0;
  border-bottom: 2px solid #c35e9e00;
}

.nav-link:hover > .nav-link-p {
  border-bottom: 2px solid #c35e9e;
}
.color__active {
  font-weight: 600;
  color: #c35e9e;
  background-color: hsla(206, 69%, 94%, 1);
  transition-duration: 0.3s;
}
.color__active:hover > .nav-link-p {
  border-bottom: 2px solid #c35e9e00;
}

.nav-a {
  color: hsla(206, 69%, 94%, 1);
  transition-duration: 0.5s;
  width: 100%;
  height: 100%;
  display: grid;
  text-decoration: none;
}

.nav-a p {
  margin: 0;
  align-self: center;
  font-weight: 500;
}

.nav-mobile {
  display: none;
}

@media (max-width: 900px) {
  .nav {
    display: none;
  }
  .nav-mobile {
    grid-template-columns: 1fr;
    width: 100%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 400px;
    backdrop-filter: blur(0px);
    grid-template-rows: 400px 60px;
    display: grid;
    position: fixed;
    top: 0;
    z-index: 100;
  }
  .navbar-menu {
    grid-column: 1/ 2;
    display: none;
  }

  .nav-li {
    min-width: 60px;
  }

  .nav-a p {
    font-size: 22px;
  }

  .navbar-menu-mobile {
    display: grid;
    transition: 0.5s;
    position: relative;
  }

  .nav-ul-mobile {
    align-self: center;
    justify-self: center;
    grid-gap: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #291a47f1;
    width: 100vw;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .nav__div {
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.171);
  }

  .nav-li-mobile {
    list-style: none;
    align-self: center;
    justify-self: center;
    min-width: 70px;
    display: grid;
    align-items: center;
    color: hsla(206, 69%, 94%, 1);
    font-size: 22px;
  }

  .nav-a {
    width: 60%;
    justify-self: center;
    border-top: 1px solid rgba(255, 255, 255, 0.397);
  }

  .boton-abrir-menu {
    color: #362360;
    font-size: 45px;
    position: fixed;
    margin-left: 85%;
    margin-top: 10px;
    opacity: 100;
    z-index: 99;
    grid-row: 2/3;
    height: 60px;
    top: 0;
    display: grid;
  }
  .boton-cerrar-menu {
    color: rgb(234, 236, 240);
    font-size: 45px;
    position: fixed;
    margin-left: 85%;
    margin-top: 10px;
    z-index: 100;
    height: 60px;
  }
}
