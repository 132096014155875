.links__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: hsla(206, 69%, 94%, 1);
  background-image: radial-gradient(
      at 35% 91%,
      hsla(269, 36%, 76%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 84% 31%, hsla(321, 45%, 56%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(22, 100%, 77%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(258, 46%, 25%, 0.54) 0px, transparent 50%);

  .links__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      cursor: pointer;
    }

    .item {
      width: 400px;
      height: 40px;
      font-weight: 800;
      border: 1px solid black;
      border-radius: 15px;
      background-color: white;
      color: rgb(32, 32, 32);
      transition-duration: 0.3s;
      margin: 0;
      cursor: pointer;
      display: flex;
      align-items: center;

      gap: 15px;
      font-size: 32px;

      span {
        font-size: 18px;
        margin-left: auto; /* Empuja el ítem hacia el centro horizontalmente */
        margin-right: auto;
        color: #291a47;
      }

      .icon {
        font-size: 26px;
        position: absolute;
        justify-self: flex-start;
        padding-left: 35px;
        display: flex;
        align-items: center;
        transition-duration: 0.3s;
        opacity: 0.8;
        color: #291a47;
      }

      &:hover {
        background-color: rgb(255, 226, 255);
        transform: translateX(2px);
      }
      &:hover > .icon {
        transform: translateX(-5px);
      }
    }
  }
}

@media (max-width: 900px) {
  .links__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      cursor: pointer;
    }

    .item {
      width: 90vw !important;
      height: 40px;
      font-weight: 800;
      border: 1px solid black;
      border-radius: 15px;
      background-color: white;
      color: rgb(32, 32, 32);
      transition-duration: 0.3s;
      margin: 0;
      cursor: pointer;
      display: flex;
      align-items: center;

      gap: 15px;
      font-size: 32px;

      span {
        font-size: 18px;
        margin-left: auto; /* Empuja el ítem hacia el centro horizontalmente */
        margin-right: auto;
      }

      .icon {
        font-size: 32px;
        position: absolute;
        justify-self: flex-start;
        padding-left: 15px !important;
        display: flex;
        align-items: center;

        transition-duration: 0.3s;
      }

      &:hover {
        background-color: rgb(255, 226, 255);
        transform: translateX(3px);
      }

      &:hover > .icon {
        transform: translateX(-3px);
      }
    }
  }
}
