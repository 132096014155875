.login__container {
  min-height: 90vh;
  display: grid;
  align-items: center;
  justify-items: center;
}

.login__container p {
  padding: 10px 15px 10px 15px;
  font-size: 16px;
  font-weight: 900;
  background-color: white;
  border-radius: 25px;
  cursor: pointer;
}
