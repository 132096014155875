.nuevaNoticia__container {
  width: 50vw;
  background-color: hsla(206, 69%, 94%, 1);
  border-radius: 15px;
  border: 1px solid #362360;
  justify-self: center;
  margin-top: 20px;
}

.nuevaNoticia__form {
  display: grid;
  justify-items: start;
  padding: 15px;
  margin: 0;
}

.nuevaNoticia__form p {
  margin: 0;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 600;
}

.nuevaNoticia__form input {
  border-radius: 5px;
  border: none;
  height: 20px;
  width: 98%;
  outline: none;
  padding-left: 10px;
}
.nuevaNoticia__textArea {
  resize: none;
  width: 96%;
  height: 150px;
  outline: none;
  padding: 10px;
  border-radius: 5px;
}

.nuevaNoticia__form button {
  justify-self: center;
  background-color: white;
  border-radius: 15px;
  padding: 15px;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}
