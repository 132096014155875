.beneficios__container {
  width: 100%;
  min-height: 300px;
  display: grid;
  justify-items: center;
  align-items: center;
}

.beneficios {
  height: 100%;
  min-width: 650px;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}

.beneficios__item {
  width: 300px;
  display: grid;
  grid-template-rows: 80px 30px 60px;
  align-items: center;
  justify-items: center;
}

.beneficios__icon {
  font-size: 46px;
  color: #c35e9e;
}

.beneficios__h4 {
  font-size: 26px;
  color: #362360;
}

.beneficios__p {
  margin-top: 30px;
  width: 60%;
  color: #362360;
  font-weight: 600;
}

@media (max-width: 900px) {
  .beneficios__container {
    padding: 20px 0 20px 0;
  }
  .beneficios {
    height: 100%;
    min-width: 300px;
    width: 95%;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
}
